import axios from 'axios';

let prefix = "/order"

const UserTicketApi = {
	
	getTicketOrderList(payload){
		return window.Vue.axios.get( prefix + "/ticket/list", {params: payload})
	},
}

export default UserTicketApi;